import React from "react";
import { Helmet } from "react-helmet";
import { Link as ScrollLink } from "react-scroll/modules";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import VideoSection from "../components/homepage/video-section";
import AbleSenseBanner from "../components/homepage/able-sense-banner";
import WorkModule from "../components/homepage/work-module";
import AltWorkModule from "../components/homepage/alt-work-module";
import HalfWorkModule from "../components/homepage/half-work-module";
import ScrollTextWorkModule from "../components/homepage/scroll-text-work-module";
import TileModule from "../components/homepage/tile-module";
import TitleModule from "../components/homepage/title-module";
import LinksList from "../components/homepage/links-list";

const IndexPage = ({ data }) => {
  const setting = data.takeshape.getSetting;
  data = data.takeshape.getHome;

  data.caseStudies = data.caseStudies.filter((cs) => {
    if (process.env.GATSBY_ENVIRONMENT === "staging" && cs.enableOnStaging)
      return true;
    if (process.env.GATSBY_ENVIRONMENT === "production" && cs.enabled)
      return true;
    return false;
  });

  const blockSize = Math.floor((data.caseStudies.length - 1) / 7) + 1;

  let blockArray = [];
  for (var i = 0; i < blockSize; i++) {
    blockArray.push(i);
  }

  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class: "mix-blend-mode--is-active homepage",
        }}
      />
      <Seo title={data.pageTitle} description={setting.seoContent} />
      <div className="main--wrap">
        <div className="smooth-scroll-block">
          <VideoSection
            title={data.title}
            video={data.bannerVideo}
            images={data.fallBackImages}
          />
          <ScrollLink
            className="anchor-link"
            to="work-module-section"
            smooth={true}
            offset={0}
            duration={500}
            isDynamic={false}
          ></ScrollLink>
        </div>

        <AbleSenseBanner {...data?.ableSenseBanner} />

        {blockArray.map((bl) => (
          <div key={bl}>
            <WorkModule detail={data.caseStudies[0 + bl * 7]}></WorkModule>
            <section className="section gs-animate">
              <div className="container container--bordered container--fluid">
                <div className="grid-layout box box--splitted box--bottom-bordered">
                  {data.caseStudies.length > 1 + bl * 7 && (
                    <HalfWorkModule detail={data.caseStudies[1 + bl * 7]} />
                  )}
                  {data.caseStudies.length > 2 + bl * 7 && (
                    <HalfWorkModule
                      detail={data.caseStudies[2 + bl * 7]}
                      alternate={true}
                    />
                  )}
                </div>
              </div>
            </section>
            {data.caseStudies.length > 3 + bl * 7 && (
              <AltWorkModule
                detail={data.caseStudies[3 + bl * 7]}
              ></AltWorkModule>
            )}
            {data.caseStudies.length > 4 + bl * 7 && (
              <ScrollTextWorkModule
                detail={data.caseStudies[4 + bl * 7]}
                next={data.caseStudies[5 + bl * 7]}
              ></ScrollTextWorkModule>
            )}
            {data.caseStudies.length > 5 + bl * 7 && (
              <TileModule
                detail={data.caseStudies[5 + bl * 7]}
                next={data.caseStudies[6 + bl * 7]}
              ></TileModule>
            )}
          </div>
        ))}
        <TitleModule title={data.aboutUs}></TitleModule>
        <LinksList {...data}></LinksList>
      </div>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    takeshape {
      getSetting {
        seoContent
      }
      getHome {
        _id
        pageTitle
        aboutUs
        ableSenseBanner {
          bannerDisplay
          linkLabel
          linkUrl
          loopText
          loopSpeed
        }
        bannerVideo {
          desktop {
            video {
              path
            }
          }
          mobile {
            video {
              path
            }
          }
        }
        caseStudies {
          enabled
          enableOnStaging
          featureTitle
          summary
          link
          featuredImage {
            path
            title
          }
        }
        contact {
          address1
          address2
          city
          email
          postCode
          state
        }
        developments {
          title
        }
        offices {
          country
          city
          timeOffset
        }
        technologies {
          title
        }
        title
        fallBackImages {
          desktop {
            asset {
              path
              title
            }
          }
          mobile {
            asset {
              path
              title
            }
          }
        }
      }
    }
  }
`;
