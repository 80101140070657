import React from "react";
import Link from "../link";
const TitleModule = ({title}) => {
  return (
    <section className="section gs-animate title-section">
      <div className="container container--bordered">
        <div className="box heading">
          <h2 className="h1 indent-text complex-heading footer-heading">
            <Link to="/" tabIndex="0" className="title-link">
              <sup>Who We Are</sup>
              {title}
            </Link>
            <Link to="/about" tabIndex="0">
              Read More
            </Link>
          </h2>
        </div>
      </div>
    </section>
  );
};

export default TitleModule;
