import React, { useEffect, useRef } from "react";
import StickyBlock from "../../js/sticky";
import Link from "../link";
import { getImageUrl } from "takeshape-routing";

const WorkModule = ({ detail }) => {

  const stickyElement = useRef();
  const limitElement = useRef();

  useEffect(() => {
    const sticky = new StickyBlock({
      stickyElement: stickyElement.current,
      limitElement: limitElement.current,
      mode: "till",
      maxWidth: 1024
    });
    return () => sticky.destroy();
  });

  return (
    <section
      ref={limitElement}
      id="work-module-section"
      className="section gs-animate work-module-section-sticky-container mobile-column-reverse"
    >
      <div className="container container--bordered">
        <div className="box box--splitted desktop-top-offset head-wrap">
          <h2
            ref={stickyElement}
            className="h1 indent-text center-indent complex-heading"
          >
            <Link to={detail.link} tabIndex="0" className="title-link">
              <sup>{detail.featureTitle}</sup>{detail.summary}
            </Link>
          </h2>
        </div>
      </div>
      <div className="container container--bordered container--fluid">
        <div
          className="box box--bordered box--splitted long-box"
          itemScope
          itemType="http://schema.org/ImageObject"
        >
          <Link to={detail.link} tabIndex="0" className="img-link mobile-position-relative">
            <img
              src={getImageUrl(detail.featuredImage.path, {auto:"compress", fit: "crop"})}
              className="mobile-absolute-pos"
              alt={detail.featuredImage.title || ''}
              itemProp="contentUrl"
            />
          </Link>
        </div>
      </div>
    </section>
  );
};

export default WorkModule;

