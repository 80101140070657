import React, { useEffect, useState } from "react";
import Link from "../link";

const MarqueeText = ({ loopText, linkLabel, linkUrl }) => {
  return (
    <div className="marquee__text">
      <p>{loopText}</p>

      <Link to={linkUrl} activeClassName="active-link">
        {linkLabel}
      </Link>
    </div>
  );
};

const ToggleButton = ({ isPlaying, handleToggle }) => {
  return (
    <button className="marquee__toggle" onClick={handleToggle}>
      {isPlaying ? (
        <svg viewBox="0 0 60 60">
          <polygon points="0,0 15,0 15,60 0,60" />
          <polygon points="25,0 40,0 40,60 25,60" />
        </svg>
      ) : (
        <svg viewBox="0 0 60 60">
          <polygon points="0,0 50,30 0,60" />
        </svg>
      )}
    </button>
  );
};

const AbleSenseBanner = ({
  bannerDisplay,
  linkLabel,
  linkUrl,
  loopText,
  loopSpeed,
}) => {
  const [isPlaying, setPlaying] = useState(true);

  useEffect(() => {
    setPlaying(true);
  }, []);

  if (!bannerDisplay) return <></>;

  return (
    <section className="able-sense-banner">
      <ToggleButton
        isPlaying={isPlaying}
        handleToggle={() => setPlaying(!isPlaying)}
      />
      <div className={`marquee ${!isPlaying ? "marquee--paused" : ""}`}>
        <div
          className="marquee__content"
          style={{
            animationDuration: `${loopSpeed ?? 40}s`,
          }}
        >
          {Array(5)
            .fill(0)
            .map((_, idx) => (
              <MarqueeText
                key={idx}
                loopText={loopText}
                linkLabel={linkLabel}
                linkUrl={linkUrl}
              />
            ))}
        </div>

        <div
          className="marquee__content"
          style={{
            animationDuration: `${loopSpeed ?? 40}s`,
          }}
          aria-hidden="true"
        >
          {Array(5)
            .fill(0)
            .map((_, idx) => (
              <MarqueeText
                key={idx}
                loopText={loopText}
                linkLabel={linkLabel}
                linkUrl={linkUrl}
              />
            ))}
        </div>
      </div>
    </section>
  );
};

export default AbleSenseBanner;
