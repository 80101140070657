import React from "react";
import Link from "../link";
import { getImageUrl } from "takeshape-routing";

const HalfWorkModule = ({detail, alternate = false}) => {
  return (
    <div className="grid-layout__child flex-column flex-column--reversed">
      <div className={`container box head-wrap ${alternate ? 'desktop-top-lg-offset' : 'desktop-top-offset'}`}>
        <h2 className="h1 indent-text complex-heading">
          <Link to={detail.link} tabIndex="0" className="title-link">
            <sup>{detail.featureTitle}</sup>{detail.summary}
          </Link>
        </h2>
      </div>
      <div
        className={alternate ? 'box box--top-bordered box--mobile-border-bottom' : 'box box--bordered'}
        itemScope
        itemType="http://schema.org/ImageObject"
      >
        <Link to={detail.link} tabIndex="0" className="img-link">
          <img
            src={getImageUrl(detail.featuredImage.path, {auto:"compress", fit: "crop"})}
            alt={detail.featuredImage.title || ''}
            itemProp="contentUrl"
          />
        </Link>
      </div>
    </div>
  );
};

export default HalfWorkModule;
