import React, { useEffect, useState } from "react";
import Clock from "react-clock";

const LinksList = ({contact, developments, offices, technologies}) => {
  
  const [dates, setDates] = useState([])
  
  useEffect(() => {
    const calOffsets = () => {
      const offsetDate = offices.map(off => getDateByOffset(off.timeOffset))
      setDates(offsetDate)
    }

    const getDateByOffset = offset => {
      const nowlocalDate = new Date();
      return new Date(
        nowlocalDate.getTime() +
          (nowlocalDate.getTimezoneOffset() + offset * 60) * 60000
      )
    }
    
    calOffsets();
    

    let intervalHandle = setInterval(() => {      
      calOffsets();
    }, 1000);

    return () => {
      clearInterval(intervalHandle);
    };
  }, [offices]);

  return (
    <div className="section gs-animate list-section">
      <div className="container container--bordered">
        <ul
          className="listreset links-list"
          itemScope
          itemType="http://schema.org/ItemList"
        >
          <li className="links-list__item" itemProp="itemListElement">
            <h3>Contact Us</h3>
            <address
              className="second-level"
              itemProp="address"
              itemScope
              itemType="http://schema.org/PostalAddress"
            >
              <span itemProp="streetAddress">{contact.address1}</span>
              <span>{contact.address2}</span>
              <span itemProp="addressLocality">
                {contact.city}, <span itemProp="addressRegion">{contact.state}</span>
                <span itemProp="postalCode"> {contact.postCode}</span>
              </span>
              <a href={`mailto:${contact.email}`}>
                {contact.email}
              </a>
            </address>
          </li>
          <li className="links-list__item" itemProp="itemListElement">
            <h3>What We Do</h3>
            <ul
              className="listreset second-level"
              itemScope
              itemType="http://schema.org/ItemList"
            >
              {developments.length > 0 && developments.map((dev, idx) => 
                <li key={idx} itemProp="itemListElement">{dev.title}</li>
              )}              
            </ul>
          </li>
          <li className="links-list__item" itemProp="itemListElement">
            <h3>Technologies</h3>
            <ul
              className="listreset second-level"
              itemScope
              itemType="http://schema.org/ItemList"
            >
              {technologies.length > 0 && technologies.map((tech, idx) => 
                <li key={idx} itemProp="itemListElement">{tech.title}</li>
              )}
            </ul>
          </li>
          <li className="links-list__item no-offset" itemProp="itemListElement">
            <h3>Offices</h3>
            <ul
              className="listreset second-level"
              itemScope
              itemType="http://schema.org/ItemList"
            >
              {offices.length > 0 && offices.map((off, idx) => 
                <li
                  key={idx}
                  itemProp="itemListElement"
                  itemScope
                  itemType="http://schema.org/CivicStructure"
                >
                  <div className="clock-wrapper">
                    <Clock
                      value={dates[idx]}
                      renderHourMarks={false}
                      renderMinuteMarks={false}
                      renderSecondHand={true}
                      hourHandWidth={1}
                      hourHandLength={60}
                      minuteHandLength={90}
                      minuteHandWidth={1}
                      size={15}
                    />
                    <p>{off.city}</p>
                  </div>
                  <p className="country-name">{off.country}</p>
                </li>
              )}
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default LinksList;
