import React, { useEffect, useRef } from "react";
import StickyBlock from "../../js/sticky";
import Link from "../link";
import { getImageUrl } from "takeshape-routing";

const ScrollTextWorkModule = ({detail, next}) => {

  const stickyElement = useRef();
  const limitElement = useRef();

  useEffect(() => {
    if (stickyElement.current && limitElement.current) {
      const sticky = new StickyBlock({
        stickyElement: stickyElement.current,
        limitElement: limitElement.current,
        minWidth: 1024
      });
      return () => {
        if(sticky)
          sticky.destroy();
      };
    }
  });

  return (
    <section className="section gs-animate">
      <div className="container container--bordered container--fluid">
        <div className="grid-layout box box--splitted box--bottom-bordered">
          {detail && <div className="grid-layout__child">
            <div
              className="box box--mobile-border-bottom long-box mobile-position-relative"
              itemScope
              itemType="http://schema.org/ImageObject"
            >
              <Link to={detail.link} tabIndex="0" className="img-link">
                <img
                  className="absolute-pos"
                  src={getImageUrl(detail.featuredImage.path, {auto:"compress", fit: "crop"})}
                  alt={detail.featuredImage.title || ''}
                  itemProp="contentUrl"
                />
              </Link>
            </div>
          </div>}
          <div className="grid-layout__child flex-column flex-column--desktop-column flex-column--content-between scroll-text">
            {detail && <div className="container alternate-module head-wrap" ref={stickyElement}>
              <h2 className="h1 indent-text complex-heading">
                <Link
                  to={detail.link}
                  tabIndex="0"
                  className="title-link"
                >
                  <sup>{detail.featureTitle}</sup>{detail.summary}
                </Link>
              </h2>
            </div>}
            {next && <div
              className="box box--top-bordered box--mobile-border-bottom desktop-hide"
              itemScope
              itemType="http://schema.org/ImageObject"
            >
              <Link to={next.link} tabIndex="0" className="img-link">
                <img
                  src={getImageUrl(next.featuredImage.path, {auto:"compress", fit: "crop"})}
                  alt={next.featuredImage.title || ''}
                  itemProp="contentUrl"
                />
              </Link>
            </div>}
            {next && <div className="container box head-wrap">
              <div ref={limitElement}></div>
              <h2 className="h1 indent-text complex-heading">
                <Link to={next.link} tabIndex="0" className="title-link">
                  <sup>{next.featureTitle}</sup>{next.summary}
                </Link>
              </h2>
            </div>}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ScrollTextWorkModule;
