import React from 'react'
import LazyImage from '../../components/lazyImage';
import {isMobile, getRandomInt} from 'js/util'
import { getImageUrl } from "takeshape-routing";

const BackgroundSection = (props) => {
  const fallback = (
    isMobile()
    ? props.images.mobile[getRandomInt(props.images.mobile.length)].asset.path
    : props.images.desktop[getRandomInt(props.images.desktop.length)].asset.path
  )

  return (
    <div>
      <LazyImage
        src={getImageUrl(fallback, {auto:"compress", fit: "crop"})}
        shouldLoad={props.isReady && !props.isVideo}
        className={props.className}
        backgroundColor={`#3F3D43`}
      >
        {props.children}
      </LazyImage>
    </div>
  )
}

export default BackgroundSection