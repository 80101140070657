import React, { useEffect, useRef } from "react";
import StickyBlock from "../../js/sticky";
import Link from "../link";
import { getImageUrl } from "takeshape-routing";

const TileModule = ({detail, next}) => {
  const stickyElement = useRef();
  const limitElement = useRef();

  useEffect(() => {
    if (stickyElement.current && limitElement.current) {
      const sticky = new StickyBlock({
        stickyElement: stickyElement.current,
        limitElement: limitElement.current,
        mode: "till",
        maxWidth: 1024
      });
      return () => {
        if(sticky)
          sticky.destroy();
      }
    }
  });

  return (
    <section className="section gs-animate">
      <div className="container container--bordered container--fluid">
        <div
          ref={limitElement}
          className="grid-layout box box--splitted box--bottom-bordered mobile-hide"
        >
          <div className="grid-layout__child container flex-column flex-column--desktop-column flex-column--content-end">
            {next &&<div className="box">
              <h2
                ref={stickyElement}
                className="h1 indent-text complex-heading"
              >
                <Link to={next.link} tabIndex="0" className="title-link">
                  <sup>{next.featureTitle}</sup>{next.summary}
                </Link>
              </h2>
            </div>}
          </div>
          {detail && <div
            className="grid-layout__child box"
            itemScope
            itemType="http://schema.org/ImageObject"
          >
            <Link to={detail.link} tabIndex="0" className="img-link">
              <img
                src={getImageUrl(detail.featuredImage.path, {auto:"compress", fit: "crop"})}
                alt={detail.featuredImage.title || ''}
                itemProp="contentUrl"
              />
            </Link>
          </div>}
        </div>
        <div className="grid-layout box box--splitted box--bottom-bordered box--mobile-border-bottom-none">
          {next && <div
            className="grid-layout__child box  box--mobile-border-bottom"
            itemScope
            itemType="http://schema.org/ImageObject"
          >
            <Link to={next.link} tabIndex="0" className="img-link">
              <img
                src={getImageUrl(next.featuredImage.path, {auto:"compress", fit: "crop"})}
                alt={next.featuredImage.title || ''}
                itemProp="contentUrl"
              />
            </Link>
          </div>}
          {next && <div className="grid-layout__child container box head-wrap">
            <h2
              ref={stickyElement}
              className="h1 indent-text complex-heading desktop-hide"
            >
              <Link to={next.link} tabIndex="0" className="title-link">
                <sup>{next.featureTitle}</sup>{next.summary}
              </Link>
            </h2>
          </div>}
        </div>
      </div>
    </section>
  );
};

export default TileModule;
