import React from "react";
import Loadable from "react-loadable";
import canAutoPlay from "can-autoplay";
import { supportsObjectFit } from "js/util";
import Loading from "../loading";
import Title from "../heroTitle";
import BackgroundSection from "./background-section";

let bannerVideo = {}
const LoadableComponent = Loadable({
  loader: () => import("./video-component"),
  render(loaded, props) {
    let Component = loaded.default;    
    return <Component {...props} {...bannerVideo}/>;
  },
  loading: Loading
});

class VideoSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isReady: false,
      isVideo: false
    };
  }

  componentDidMount() {
    bannerVideo = this.props.video
    setTimeout(() => {
      canAutoPlay.video({ inline: true, muted: true }).then(({ result }) => {
        this.setState({
          isReady: true,
          isVideo: result && supportsObjectFit()
        });
      });
    }, 0);
  }

  render() {
    const {title} = this.props
    return (
      <section
        className="video-section-wrap"
        style={{ backgroundColor: "#3F3D43" }}
      >
        <BackgroundSection
          className="section video-section"
          isReady={this.state.isReady}
          isVideo={this.state.isVideo}
          images={this.props.images}
        >
          {this.state.isReady && this.state.isVideo && <LoadableComponent />}
          <div className="container container--light-bordered desktop-hide"></div>
        </BackgroundSection>
        <div className="holder holder--light-bordered">
          <div className="container">
            <div className="wrapper">
              <div className="video-section__content">
                <Title
                  className="indent-text indent-text--desktop"
                  itemProp="name"
                >
                  {title}
                </Title>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default VideoSection;
