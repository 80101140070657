// eslint-disable-next-line
/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { getImageUrl } from "takeshape-routing";

import StickyBlock from "../../js/sticky";
import Link from "../link";
const AltWorkModule = ({detail}) => {
  useEffect(() => {
    setTimeout(() => {
      setHeight(getHeight());
    }, 200);

    resizeDetectInit();
    const sticky = new StickyBlock({
      stickyElement: stickyElement.current,
      limitElement: limitElement.current,
      mode: "till",
      maxWidth: 1024
    });
    return () => {
      resizeDetectDestroy();
      sticky.destroy();
    };
  }, []);

  const stickyElement = useRef();
  const limitElement = useRef();
  const headingWrapRef = useRef();
  const getHeight = () => {
    const windowWidth = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;

    return windowWidth > 1023 ? -headingWrapRef.current.offsetHeight : 0;
  }

  const [divHeight, setHeight] = useState(0);
  const divStyle = {
    marginTop: divHeight
  };

  const onResize = () => {
    setHeight(getHeight());
  };

  const resizeDetectInit = () => {
    window.addEventListener("resize", onResize);
  };

  const resizeDetectDestroy = () => {
    window.removeEventListener("resize", onResize);
  };

  return (
    <section
      ref={limitElement}
      className="section gs-animate alternate-module mobile-column-reverse"
      style={divStyle}
    >
      <div className="container container--bordered box--mobile-border-bottom">
        <div
          className="box box--splitted desktop-top-offset head-wrap"
          ref={headingWrapRef}
        >
          <h2
            ref={stickyElement}
            className="h1 indent-text complex-heading complex-heading--half"
          >
            <Link to={detail.link} tabIndex="0" className="title-link">
              <sup>{detail.featureTitle}</sup>{detail.summary}
            </Link>
          </h2>
        </div>
      </div>
      <div className="container container--bordered container--fluid">
        <div
          className="box box--bottom-bordered box--splitted long-box"
          itemScope
          itemType="http://schema.org/ImageObject"
        >
          <Link to={detail.link} tabIndex="0" className="img-link mobile-position-relative">
            <img
              src={getImageUrl(detail.featuredImage.path, {auto:"compress", fit: "crop"})}
              className="mobile-absolute-pos"
              alt={detail.featuredImage.title || ''}
              itemProp="contentUrl"
            />
          </Link>
        </div>
      </div>
    </section>
  );
};

export default AltWorkModule;
